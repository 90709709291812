.qu_container {
  width: 80%;
  padding:30px calc(8%);
  margin:0 auto;
  max-width: 1440px;
  background-color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  letter-spacing: 0.07em;
 
}

.questionnaire_title {
  width: 100%;
  height: 60px;
  background-color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
 
}

.question_title {
  margin-top: 20px;
  margin-bottom: 10px;
}

.select_group {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: start;
}

.select_group:last-of-type {
  margin-bottom: 20px;
}
.select_control {
  flex-shrink: 0;
  width: 250px;
  padding-right: 25px;
  border: 1px solid #000000;
  border-radius: 10px;
  overflow: hidden;
}

.sub_question {
  padding-left: 2rem;
}


/* .input_group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  min-height: 50px;
} */
/* .input_label {
  display: flex;
  flex-wrap: wrap;
  min-width: 60%;
} */

/* .form-label{
  max-width: 72%;

} */
.input_control {
  flex-shrink: 0;
  width: 250px;
  height: 38px;
  border: 1px solid #000000;
  border-radius: 10px;
}
.multi_select_box {
  margin-top: 20px;
}

.instructions_box {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.instruction_text {
  max-width: 80%;
}

.checkbox_group {
  display: flex;
  justify-content: flex-start;
  margin-left: 2em;
  margin-bottom: 8px;
  
}



.checkbox_check {
  padding-top: 1px;
  margin-right: 10px;
}



.select_label {
  max-width: 70%;
}

.inlineContainer {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

 .qu_container textarea{
    display: block;
    width:100%;
    padding:10px;
    border: 1px solid #000000;
  border-radius: 10px;

}

@media only screen and (max-width: 768px) {
  .qu_container {
    width: 90%;
  }
  .select_label{
    max-width:100% ;
  }
  .select_group {
    flex-direction: column;
  }
  .question_title{
    font-size: 16px;
  }
}