.position-relative {
  position: relative;
}

.between-fields-text {
  /* position: absolute; */
  /* left: 50%; */
  transform: translateX(-50%);
  margin-top: 4px;
  font-size: 1rem;
  margin-left: 30%;
  width: 3rem;
  /* text-align: center; */
}

@media (max-width: 600px) {
  .between-fields-text {
    font-size: 0.8rem;
  }
}