.input-group-append > select.form-control,
.input-group-append > select.custom-select {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  width: auto;
}
.questionnaire_title {
  width: 100%;
  height: 60px;
  background-color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
 
}

.popup-overlay {
  position: fixed; /* Overlay for full-screen coverage */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex; /* Center the popup */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  z-index: 999; /* Ensure it's above other content */
}

.save-popup {
  position: relative; /* Needed for positioning the close button */
  background-color: #fff; /* White background */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Space inside the popup */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  width: 80%; /* Responsive width */
  max-width: 500px; /* Maximum width */
  text-align: center; /* Center text */
}

.save-popup p {
  margin-bottom: 20px; /* Space below the message */
  font-size: 16px; /* Font size for the message */
  color: #333; /* Dark text color */
}

.save-popup-buttons {
  display: flex; /* Use flexbox for buttons */
  justify-content: space-between; /* Space buttons evenly */
}

.save-popup-buttons button {
  flex: 1; /* Make buttons take equal space */
  margin: 0 5px; /* Margin between buttons */
  padding: 10px; /* Padding inside buttons */
  border: none; /* Remove default border */
  border-radius: 4px; /* Rounded corners for buttons */
  cursor: pointer; /* Pointer on hover */
  transition: background-color 0.3s; /* Smooth background transition */
}

.save-popup-buttons button.btn-primary {
  background-color: #007bff; /* Primary button color */
  color: white; /* Text color for primary button */
}

.save-popup-buttons button.btn-primary:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.save-popup-buttons button.btn-secondary {
  background-color: #6c757d; /* Secondary button color */
  color: white; /* Text color for secondary button */
}

.save-popup-buttons button.btn-secondary:hover {
  background-color: #5a6268; /* Darker shade on hover */
}

/* Close button styling */
.save-popup .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px; /* Cross button size */
  color: #333; /* Dark color for the cross */
  cursor: pointer; /* Pointer cursor */
  font-weight: bold;
}

.save-popup .close-button:hover {
  color: #ff0000; /* Red color on hover */
}

@media (max-width: 600px) {
  .save-popup {
    padding: 15px; /* Less padding for smaller screens */
  }

  .save-popup p {
    font-size: 14px; /* Smaller font size */
  }

  .save-popup-buttons button {
    padding: 8px; /* Smaller button padding */
  }
}

