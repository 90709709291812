/* account-settings.css */

.account-settings-container {
  margin: auto;
  width: 400px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
}

.account-settings-header {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.05em;
  font-weight: 400;
  margin-top: 40px;
  color: #000000;
}

.account-settings-field {
  margin-top: 10px;
}

.account-settings-label {
  color: #000000;
}

.account-settings-input,
.account-settings-select {
  width: 100%;
  height: 32px;
  padding: 0 12px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  border-radius: 10px;
  margin-top: 5px;
  color: #000000;
}

.account-settings-select {
  appearance: none;
  -webkit-appearance: none;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 30px;
}

.account-settings-button {
  background: #000000;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3%;
  width: 220px;
  height: 32px;
}
