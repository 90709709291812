html, body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.subscription {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the full viewport height is taken */
  font-family: "Montserrat", sans-serif;
  background-color: #f7f7f7; 
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 15px;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  margin: 10px 0;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.error-message-icon {
  margin-right: 10px;
}

.sub_top {
  flex-shrink: 0;
  height: 130px;
  background-color: #f0edea;
  opacity: 1;
}

.sub_second {
  flex-shrink: 0;
}

.sub_top_script {
  font-size: 32px;
  text-align: center;
  padding-top: 65px;
  font-weight: 500;
}

.sub_up {
  width: 100%;
  height: 90px;
  background-color: #455A64;
  opacity: 0.9;
}

.sub_up_script {
  padding-top: 20px;
  font-size: 24px;
  font-weight: 200;
  text-align: center;
  color: #ECEFF1; 
}

.sub_card {
  background-color: #ffffff; 
  /* margin: 15px 20px 0px 20px;  */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease; 
  width: 100%; 
  max-width: 500px; 
  height: auto; 
}

.sub_card:hover {
  transform: translateY(-5px); 
  box-shadow: 0 6px 12px rgba(0,0,0,0.2);
}

.sub_card button {
  background-color: #1E88E5; 
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: bold;
}

.sub_card button:hover {
  background-color: #1565C0; 
}

.modal-content {
  border-radius: 10px;
  border: 1px solid #dee2e6;
  box-shadow: 0 5px 15px rgba(0,0,0,.5);
}

.modal-header {
  background-color: #455A64;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.modal-body {
  padding: 20px;
  font-family: "Montserrat", sans-serif;
}

.modal-footer {
  border-top: none;
}

.modal-footer .btn {
  font-weight: bold;
}

body {
  position: relative; 
  min-height: 100vh;
  margin: 0;
}

.plans-container {
  display: flex;
  flex-direction: column; 
  justify-content: center; 
  align-items: flex-start; 
  gap: 20px; 
  flex-wrap: nowrap; 
}

.content-container {
  flex: 1; /* This will allow the content to take up the remaining space between header and footer */
  display: flex;
  flex-direction: column; 
  align-items: center; 
  justify-content: flex-start; 
  overflow-y: auto; /* Makes the content scrollable */
  padding-bottom: 20px;
}

.cards-container {
  display: flex;
  justify-content: center; 
  align-items: flex-start; 
  flex-wrap: wrap; 
  gap: 20px; 
  /* margin-bottom: 10px; */
  margin: 15px;
}

.enroll-button-container {
  display: flex;
  justify-content: center;
  width: 100%; 
  /* margin-top: 20px; Ensure there's space between cards and button */
}

.enroll-button {
  display: inline-block; 
  padding: 15px 30px; 
  font-size: 18px; 
  color: white; 
  background-color: #007bff; 
  border-radius: 8px; 
  cursor: pointer; 
  transition: background-color 0.3s ease; 
  text-align: center; 
  width: 200px; 
  height: auto; 
}

.enter-code-button {
  display: inline-block; 
  padding: 15px 25px; 
  font-size: 13px; 
  color: white; 
  background-color: #007bff; 
  border-radius: 5px; 
  cursor: pointer; 
  transition: background-color 0.3s ease; 
  text-align: center; 
  width: 100px; 
  height: auto; 
  margin-top: 10px;
}

.enroll-button:hover {
  background-color: #0056b3; /* Slightly darken the button color on hover */
}

.payment-form-container {
  max-width: 700px;
  width: 100%;
  background-color: #fff; 
  padding: 20px; 
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); 
  border-radius: 8px; 
  margin: 20px auto;
  max-height: calc(100vh - 200px); /* Adjust as needed */
  /* overflow-y: auto; Make the payment form scrollable if content is too long */
}

.payment-form-container h2 {
  font-size: 26px; 
  color: #333; 
  margin-bottom: 16px; 
  text-align: center; 
  font-weight: normal; 
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader-small {
  border: 3px solid #f3f3f3; /* Thin border */
  border-top: 3px solid #3498db; /* Bright color for the loader */
  border-radius: 50%;
  width: 25px; /* Smaller size */
  height: 25px;
  animation: spin 2s linear infinite;
}

.payment-update {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.custom-modal-backdrop {
  background-color: rgba(0, 0, 0, 0.3);
}

.custom-modal-content {
  max-width: 80%;
  max-height: 80%;
  margin: auto;
}

.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

/* Responsive design: stack the cards in a column on smaller screens */
@media (max-width: 768px) {
  .cards-container {
    flex-direction: column; 
  }

  .enroll-button-container {
    margin-top: 20px; 
  }
}

footer {
  flex-shrink: 0; /* Ensures the footer does not shrink and stays at the bottom */
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  width: 100%;
}
