.message-area {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  overflow: hidden;
  padding: 10px;
  text-align: center;
  z-index: 1000; /* Ensure it stays above other elements */
}

.message-container {
  display: flex;
  flex-direction: column-reverse; /* Display messages in reverse order */
  height: 100%;
  overflow: hidden;
}

.message {
  padding: 5px;
  margin: 5px;
  border: 1px solid #fff;
}