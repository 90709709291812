.refresh-button {
  position: fixed;
  bottom: 24.5%;
  right: 27.5%;
  transform: translateY(-50%);
  padding: 8px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
}

.refresh-button:hover {
  background-color: #0056b3;
}
