.form-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px; 
}


@media (max-height: 700px) {
  .form-wrapper {
      padding: 10px;
  }
}


.payment-page-container {
    background: linear-gradient(135deg, #4facfe 0%, #00f2fe 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    box-sizing: border-box; 
}

.stripe-form-container {
    background: #ffffff;
    border-radius: 8px;
    padding: 40px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
    width: 100%;
    max-width: 700px;
    margin: auto;
    position: relative;
    overflow: hidden; 
}
  
  /* 样式化表单元素 */
  .stripe-card-form {
    max-width: 600px; 
    max-height: 600px;
    width: 100%; 
    position: relative;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .stripe-card-element {
    padding: 16px; 
    width: 100%;
    padding: 12px 14px;
    background: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  
  /* 按钮样式 */
  .stripe-submit-btn {
    padding: 16px 20px;
    background-color: #1E88E5; 
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px; 
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.stripe-submit-btn:hover {
    background-color: #1565C0; 
    transform: translateY(-2px); 
}
  

 .payment-success-message {
    text-align: center;
    background: #eaffea; 
    border-left: 5px solid #4caf50; 
    padding: 20px;
    border-radius: 8px;
    margin-top: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
  
  .payment-success-message h2 {
    margin-bottom: 20px;
    color: #4caf50; 
  }
  
  .payment-success-message button {
    background-color: #008CBA;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  /* 价格显示和公司Logo */
  .price-display {
    margin-bottom: 20px;
  }
  
  .form-header {
    display: flex;
    align-items: flex-start; 
    justify-content: space-between; 
    width: 100%; 
}

.form-logo {
    position: absolute; 
    top: 16px; 
    left: 16px; 
    width: 100px; 
    margin-right: 20px; 
    margin-bottom: -50px; 
    position: absolute; 
    top: 0; 
    left: 0; 
}