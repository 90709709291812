/* login and signUp page */

.login_title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.05em;
  margin-top: 40px;

  color: #000000;
}

.login_box {
  margin: auto;
  width: 400px;
  font-family: 'Montserrat';
  font-style: normal;
}

.login_label {
  margin-top: 10px;
}
.lable_box {
  box-sizing: border-box;

  width: 251px;
  height: 32px;
  left: 579px;
  top: 287px;
  padding-left: 12px;
  padding-right: 12px;

  background: #ffffff;
  border: 1px solid #cdcdcd;
  border-radius: 10px;
  margin-top: 5px;
}
.login_submit {
  width: 141px;
  height: 32px;
  color: #ffffff;

  background: #000000;
  border-style: none;
  border-radius: 10px;
  box-shadow: none;
  margin-left: 12%;
}

/* Add styles for the dialog */
.dialog p {
  font-size: 16px;
  margin-bottom: 10px;
}

.dialog-option {
  margin-bottom: 10px;
}

.dialog label {
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  font-size: 16px;
}

.dialog input[type='checkbox'] {
  vertical-align: middle;
  margin-right: 5px;
}
.dialog-wrapper {
  background-color: #f0f0f0; /* Background color for the dialog wrapper */
  border: 1px solid #cdcdcd; /* Border for the dialog wrapper */
  border-radius: 10px;
  padding: 10px; /* Padding to separate the dialog from the content */
  margin-top: 20px;
}

.action-button {
  background-color: #000000;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  padding: 8px 16px;
  margin-top: 10px;
  cursor: pointer;
}

.error-message {
  color: #ff0000;
  font-size: 14px;
}

.info-message {
  color: #333333;
  font-size: 16px;
  margin-bottom: 10px;
}

.verification-code-input {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.verification-code-input input {
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px;
}
