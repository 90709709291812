.multi-step-container {
    width: 80%;
    margin: auto;
    padding: 20px;
  }
  
  .stepper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 20px; */
    margin-top: 30px;
    position: relative;
  }
  
  .step {
    text-align: center;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  
  .step-number {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    z-index: 2;
    position: relative;
  }
  
  .step-label {
  font-size: 14px;
  margin-top: 20%;
  position: absolute;
  top: 90%; 
  text-align: center;
  width: max-content; 
  /* padding: 5px 10px;  */
  }
  
  /* Stepper Line */
  .step-line {
    position: absolute;
    top: 50%;
    left: -50%;
    width: 100%;
    height: 4px;
    background: #ddd;
    z-index: 1;
  }
  
  .step:first-child .step-line {
    display: none;
  }
  
  /* Active Step */
  .step.active .step-number {
    background: rgb(0, 140, 255);
    color: white;
  }
  .connector {
    flex-grow: 1;
    width: 10px;
    content: "";
    display: block;
    height: 1px;
    background-color: Grey;
  }

  
  /* Completed Step */
  .step.completed .step-number {
    background: rgb(2, 158, 49);
    color: white;
    
  }
  
  /* Connect Completed Steps */
  .connector.completed {
    background: green;
  }

 .step-status{
    color: grey;
    font-size: 12px;
 }

 .connector:last-child::after {
    display: none;
  }


  @media (max-width: 600px) {
    .stepper {
      flex-direction: column;
      align-items: center;
    }
    
    .step {
      display: none; /* Hide all steps */
    }
  
    .step.active {
      display: flex; /* Show only active step */
    }

    .step-label {
      display: None;
    }

    .connector{
        display: None;
    }
  }

  .step-number:hover {
    border-radius: 50%;
    transform: scale(1.2);
    box-shadow: 0px 0px 20px rgb(153, 1, 255);
  }