/* Center the content on the page */
.payment-history-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f9f9f9;
  padding: 20px;
}

/* Style the heading */
.payment-history-container h1 {
  margin-bottom: 20px;
  font-size: 2rem;
  color: #333;
}

/* Card number input container */
.card-input-container {
  margin-bottom: 20px;
  text-align: center;
}

.card-input-container label {
  font-size: 1rem;
  color: #333;
  margin-right: 10px;
}

.card-number-inputs {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.card-slot {
  width: 50px;
  padding: 10px;
  text-align: center;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.card-slot:focus {
  border-color: #4CAF50;
  outline: none;
}

/* Style the table container */
.table-container {
  max-width: 80%;
  overflow-x: auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

/* Style the table */
.payments-table {
  width: 100%;
  border-collapse: collapse;
}

.payments-table th,
.payments-table td {
  border: 1px solid #ddd;
  text-align: left;
  padding: 8px;
}

.payments-table th {
  background-color: #4CAF50;
  color: white;
}

.payments-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.payments-table tr:hover {
  background-color: #ddd;
}

/* Display when no payments are found */
.no-payments {
  text-align: center;
  color: #888;
  font-size: 1.2rem;
  margin-top: 20px;
}

/* Loading indicator */
.loading {
  font-size: 1.5rem;
  color: #555;
  font-weight: bold;
}
