/* intervention select */
.intervention_sug_box{
	height: 102px;
	background: #FFFFFF;
	margin-top: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	/* opacity: 0.5; */
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.intervention_sug_txt{
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;

	color: #000000;
	display: flex;
	justify-content: center;
	align-items: center;
}


.intervention_box{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	
	padding: 100px 167px;
}

.intervention_title{
	height: 79px;
	width: 750px;

	background: rgba(255, 116, 79, 0.8);
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;

	color: #FFFFFF;
	padding: 0px 30px;
	display: flex;
	align-items: center;
}

.intervention_item_box{
	height: 79px;
	background: #FFFFFF;
	width: 750px;
	/* box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25); */
	padding: 0px 30px;
	margin-top: 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #FFFFFF;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
	
}
.intervention_item_content{
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 15px;
	/* identical to box height */

	margin-right: 20px;
	color: #000000;

}
.inlineContainer {
	text-align: center;
	margin-top: 40px;
	margin-bottom: 40px;
  }
  