* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  
  .questionnaire_title {
    width: 100%;
    height: 60px;
    font-size: 32px;
    line-height: 44px;
    margin-top: 60px;
    margin-bottom: 4px;
    text-align: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;
  }
  
  .cal_container {
    width: 80%;
    padding: 10px calc(12%);
    margin: 0 auto;
    max-width: 1440px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    font-family: "Montserrat";
    font-size: 18px;
    color: #000000;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.07em;
  }
  
  .cal_select_group {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: start;
  }
  
  .cal_select_group:last-of-type {
    margin-bottom: 20px;
  }
  
  .cal_select_control {
    flex-shrink: 0;
    width: 250px;
    padding-right: 25px;
    border: 1px solid #000000;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .cal_input_control {
    flex-shrink: 0;
    width: 250px;
    border: 1px solid #000000;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .cal_input-container {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
  
  .cal_input {
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #000000;
    border-radius: 10px;
    overflow: hidden;
    padding-right: 25px;
  }
  
  .inlineContainer {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .form-text {
    font-size: 20px;
    font-weight: 600;
    color: #1f1f1f;
  }
  
  @media screen and (max-width: 768px) {
    .cal_container {
      width: 90%;
    }
    .inlineContainer {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .cal_select_group {
      flex-direction: column;
    }
  }
  
  .tabs-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 100px;
  }
  
  .tabs-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    position: relative;
  }
  
  .tabs-header .tab {
    transition: transform 0.2s ease; /* Smooth transition for scaling */
  }

  .tabs-header .tab:hover {
    transform: scale(1.1); /* Scale up the tab on hover */
  }

  .tabs-header::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background: #e0e0e0;
    z-index: 1;
  }
  
  .tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 2;
    background: #F5F5F5;
    padding: 0 15px;
  }
  
  .tab-number {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    transition: all 0.2s ease;
  }
  
  .tab.error .tab-number {
    background: #fff;
    color: #dc3545;
    border-color: #dc3545;
  }
  
  .tab.incomplete .tab-number {
    background: #fff;
    color: #dc3545;
    border-color: #dc3545;
  }
  
  .tab.active.error .tab-number,
  .tab.active.incomplete .tab-number {
    background: #dc3545;
    color: white;
    border-color: #dc3545;
  }
  
  .tab.completed .tab-number {
    background: #28a745;
    color: white;
    border-color: #28a745;
  }
  
  .tab-name {
    font-size: 14px;
    color: #666;
  }
  
  .tab.active .tab-name {
    color: #007bff;
    font-weight: bold;
  }
  
  .tab-status {
    font-size: 12px;
    color: #999;
  }
  
  .tab-content {
    background: white;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .navigation-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .navigation-buttons button:disabled {
    background: #e0e0e0;
    color: #999;
    cursor: not-allowed;
  }
  
  /* Add a visual indicator for incomplete fields */
  .required-field {
    border-color: #dc3545;
  }
  
  .required-field::placeholder {
    color: #dc3545;
  }
  
  /* Add these styles for form inputs */
  .form-group {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .input-label {
    flex: 1;
    text-align: left;
    font-size: 14px;
    color: #333;
    font-weight: 500;
    height: 40px;
    display: flex;
    align-items: center;
  }
  
  .input-container {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 300px;
  }
  
  .form-control {
    flex: 1;
    height: 40px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 14px;
    background-color: #fff;
  }
  
  .unit-label {
    min-width: 120px;
    height: 40px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 8px 12px;
    background-color: #f8f9fa;
    color: #666;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .exercise-label {
    height: 40px;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 8px 12px;
    font-size: 14px;
    color: #666;
  }
  
  .instruction-text {
    font-size: 12px;
    color: #666;
    margin-top: 4px;
    font-style: italic;
  }
  
  /* Style for radio buttons and checkboxes */
  .radio-group {
    display: flex;
    gap: 20px;
  }
  
  .radio-label {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }
  
  /* Add hover effects */
  .form-control:hover, .unit-selector:hover {
    border-color: #ccc;
  }
  
  .form-control:focus, .unit-selector:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.1);
    outline: none;
  }
  
  .error-text {
    color: red;
    font-size: 14px;
    margin: 4px 0 0 0;
  }
  
  .instruction-text {
    color: #666;
    font-size: 12px;
    margin: 4px 0 0 0;
    font-style: italic;
  }
  
  .tab.error .tab-name {
    color: #dc3545;
  }
  
  .tab.incomplete .tab-name {
    color: #dc3545;
  }
  
  .tab.active.error .tab-name,
  .tab.active.incomplete .tab-name {
    color: #dc3545;
    font-weight: bold;
  }
  