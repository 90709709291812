/* TermsAndConditions.css */
.terms-conditions-container {
  max-width: 800px; /* or your preferred max width */
  margin: auto;
  padding: 50px 20px;
  font-family: Arial, sans-serif; /* Use whatever font you prefer */
  line-height: 1.6; /* Adjust line height for readability */
  max-height: 600px;
  overflow-y: auto;
  padding-top: 100px;
}

.terms-conditions-container h2 {
  margin-top: 24px;
  margin-bottom: 16px;
  color: #333; /* Adjust the color to fit your brand */
}

.terms-conditions-container h3 {
  margin-top: 18px;
  margin-bottom: 12px;
}

.terms-conditions-container p {
  margin-bottom: 12px;
}

/* TermsAndConditions.css */
.company-logo-container {
  text-align: center;
  margin-top: 50px;
}

.company-logo {
  max-width: 200px;
  height: auto;
  border: 5px solid #000;
  border-radius: 10px;
  padding: 5px;
  background-color: white;
  margin: 10px;
}

@media (max-width: 600px) {
  .terms-conditions-container {
    width: 90%;
    padding: 15px;
    margin: 0 auto;
  }

  .company-logo-container {
    margin-top: 30px;
  }

  .company-logo {
    max-width: 150px;
  }
}

/* Add additional styles as needed */
