/* profile-response page */
.profile_sug_box {
	height: 150px;
	background: #ffffff;
	display: flex;
	/* opacity: 0.5; */
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	padding-top: 50px;
  }
  .profile_sug_txt {
	margin: 0 auto;
	top: 81px;
	text-align: center;
	width: 682px;
	height: 62px;
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
  
	color: #000000;
  }
  .lgr_recommand_box {
	margin: auto;
	height: 60px;
	width: 600px;
	margin-top: 30px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	letter-spacing: 0.05em;
	color: #000000;
  }
  .categories_box {
	width: 85%;
	margin: 0 auto;
	padding: 40px 150px;
	overflow: hidden;
  }
  
  .categories_item {
	height: 151px;
	background: #ffffff;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
	padding: 20px;
	font-family: "Inter";
	font-style: normal;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-bottom: 80px;
  }
  
  .categories_item_title {
	font-weight: 700;
	font-size: 18px;
	line-height: 19px;
	margin-bottom: 20px;
  
	color: #ff5964;
  }
  .categories_item_content {
	display: flex;
	justify-content: space-between;
  }
  .categories_item_content_left {
	width: 88%;
	height: 86px;
	padding: 6px;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #000000;
	font-family: "Inter";
	font-style: normal;
	overflow: hidden;
  }
  .categories_item_content_right {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 17px;
	color: #000000;
	/* width: 120px; */
	margin-left: 15px;
  }
  
  .current_prob {
	font-family: "Montserrat";
	font-style: normal;
	/* font-weight: 400; */
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0.05em;
	color: #000000;
	margin: 0 auto;
	width: 696px;
	height: 24px;
	margin-bottom: 30px;
  }
  
  .lifespan_box {
	width: 40%;
	height: 670px;
	margin: auto;
  }
  .lifespan_estimate_title_box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 205px;
	margin-bottom: 40px;
	background: #ffffff;
	border: 1px solid #cecece;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
  }
  
  .lfe_title {
	font-size: 24px;
	line-height: 29px;
	letter-spacing: 0.05em;
  
	color: #202020;
	margin-top: 25px;
  }
  .lfe_number {
	font-size: 96px;
	line-height: 117px;
	/* identical to box height */
  
	letter-spacing: 0.05em;
  
	color: #202020;
  }
  
  .lifespan_estimate_content_box {
	display: flex;
	justify-content: space-between;
	padding: 20px;
  
	background: #ffffff;
	border: 1px solid #cecece;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
  
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 12px;
	letter-spacing: 0.05em;
	color: #000000;
  }
  
  .lfe_disease_box {
	display: flex;
	flex-direction: column;
	width: 70%;
  }
  .lfe_disease_title {
	text-decoration-line: underline;
	font-size: 16px;
	line-height: 17px;
  }
  
  .lfe_probability_box {
	font-size: 14px;
	display: flex;
	flex-direction: column;
  }
  
  .refresh_cal_button {
	width: 233px;
	height: 65px;
	margin: auto;
  
	display: flex;
	justify-content: center;
	align-items: center;
  
	background: #ff744f;
	border-radius: 10px;
  
	margin-top: 40px;
	margin-bottom: 60px;
  }
  .refresh_button_title {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	/* identical to box height */
  
	letter-spacing: 0.05em;
  
	color: #000000;
  }
  
  .end_descripe {
	width: 965px;
	height: 48px;
  
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	letter-spacing: 0.05em;
  
	color: #000000;
	margin: auto;
  
	margin-top: 50px;
  }