.navigation {
  display: flex;
  justify-content: space-between;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  overflow: visible;
  width: 100%;
  height: 60px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 15px;
  line-height: 20px;
  padding-left: 15px;
  padding-right: 15px;
  letter-spacing: 0.05em;
  color: #000000;
  z-index: 999;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
}
.selection > li:hover{
  color: gray;
}
.button:hover{
  color: gray;
  /* background-color: rgb(140, 138, 138); */
}
@media only screen and (max-width:1200px){
  .selection li{
    font-size: 9px;
    letter-spacing: 0.04em;
  }
  .status li {
    font-size: 9px;
    letter-spacing: 0.04em;
  }
  .button {
    font-size: 9px;
    letter-spacing: 0.04em;
  }  
}

@media only screen and (max-width:850px){
  .selection li{
    font-size: 9px;
    letter-spacing: 0.02em;
  }
  .status li{
    font-size: 9px;
    letter-spacing: 0.02em;
  }
  .button {
    font-size: 9px;
    letter-spacing: 0.02em;
  }  
}

.logo {
  display: block;
  align-items: center;
  background: url("./images/icon1.png") no-repeat 0px 5px;
  background-size: 50px 50px;
  width: 120px;
}

.selection {
  flex-grow: 1;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  justify-content: center;
  padding-left: 0;
  width: 80%;
  list-style: none;
}

.selection li {
  width: 110px;
  text-align: center;
}
.navigation li a{
  text-decoration: none;
}

.status {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  position: relative;
  /* width: 180px; */
  /* width: 20%; */
  align-items: center;
  font-size: 15px;
  margin-bottom: 0;
  padding-left: 0;
  margin-right: 0;
  text-align: center;
  list-style: none;
  color:#000000;
}

.button {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  border: none;
  font-weight: 550;
}


.phone {
  display: none;
}

@media screen and (max-width: 768px) {
  .navigation {
    display: none;
  }

  .phone {
    display: flex;
    background-color: black;
    justify-content: space-between;
    position:fixed;
    text-align: center;
    list-style: none;
    top: 0;
    left: 0;
    overflow: visible;
    width: 100%;
    height: 60px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 450;
    font-size: 15px;
    line-height: 40px;
    padding-right: 15px;
    letter-spacing: 0.02em;

    color: #ffffff;
    flex-grow: 1;
    padding-left: 0;
    list-style: none;
    z-index: 999;
  }
  .phone li{

    margin-top: 10px;
  }
  .phone_button .navbar-brand{
    margin-right: 240px;
  }
  .phone_show{
    top: 40px
  }

  .phone_button .phone_checkbox {
    position: absolute;
    height: 32px;
    width: 32px;
    top: 15px;
    left: 20px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }
  .hamburger-lines {
    display: block;
    height: 26px;
    width: 32px;
    position: absolute;
    top: 17px;
    left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .hamburger-lines .line {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: white;
  }
  
  .line1{
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
  }
  .line2{
    transition: transform 0.2s ease-in-out;
  }
  .line3{
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
  }
  .phone_menu_items{
    padding-top: 50px;
    box-shadow: inset 0 0 2000px rgba(92, 87, 87, 0.7);
    background-color: black;
    height: 90vh;
    width: 81%;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    margin-left: -40px;
    padding-left: 50px;
    padding-right: -10px;
    transition: transform 0.5s ease-in-out;
    text-align: center;
    opacity: 0.9;
    
  }
  .phone_menu_items li{
    font-size: large;
    color: white;
    padding-bottom: 5%;
  }
  .phone_menu_items li:hover{
    color: gray;
  }
  .phone_button [type="checkbox"]:checked ~ .phone_menu_items {
    transform: translateX(0);
  }
  .phone_button input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg);
  }
  .phone_button input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
  }
  .phone_button input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-45deg);
  }
  .phone_button input[type="checkbox"]:checked ~ .navbar-brand{
    display: none;
  }
}

@media only screen and (max-width:530px){
  .phone_button .navbar-brand{
    margin-right: 20px;
  }
}


.nav_content {
  display: none;
  position: absolute;
  background-color: rgb(45, 42, 42);
  width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  opacity: 0.7;
}

.my_account {
  color: white;
  text-decoration: none;
  display: block;
  font-size: 14px;
}




/* Change color of dropdown links on hover */
.nav_dropdown a:hover {background-color: rgb(80, 83, 80);}

/* Show the dropdown menu on hover */
.nav_dropdown:hover .nav_content {display: block; z-index: 1000;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.nav_dropdown:hover .nav_btn {background-color: rgb(70, 63, 63);}

/* improve */
.nav_dropdown {
  position: relative; 
}

.nav_btn {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  background: none; 
  border: none; 
  padding: 0.5rem 0.75rem; 
  color: #000000; 
  cursor: pointer;
}

/* Drop-down menu style */
.nav_content {
  display: none;
  position: absolute;
  left: 50%; 
  transform: translateX(-50%); 
  background-color: #455A64; 
  min-width: 150px; 
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  border-radius: 4px; 
  overflow: hidden; 
  z-index: 1000; 
}

/* Personal account link style */
.nav_content a {
  color: white;
  background-color: #455A64; 
  text-decoration: none;
  display: block;
  font-size: 14px;
  padding: 12px 20px; 
  border-radius: 4px; 
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  margin-top: 10px; 
}

.nav_content a:hover {
  background-color: #546E7A; 
  color: #ffffff;
  transform: translateY(-2px); 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

.nav_dropdown:hover .nav_content {
  display: block; 
}

.nav_dropdown:hover .nav_btn {
  background-color: #546E7A; 
}

