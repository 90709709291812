.fade-box-container {
    display: flex;
    justify-content: center;
    opacity: 1;
    animation: fadeOut 2s ease-in-out forwards;
}

.fade-box {
    /* width: 300px;
    height: 100px; */
    /* background-color: #4CAF50; */
    color: black;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
}

/* Animation keyframes */
@keyframes fadeOut {
    0% {
        opacity: 1; /* Fully visible at start */
    }
    60% {
        opacity: 1; /* Stay visible until 80% */
    }
    100% {
        opacity: 0; /* Fully transparent by the end */
        display: none;
    }
}