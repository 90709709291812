.home {
  font-family: "Montserrat";
}

.home .home_module:nth-child(1) {
  /* background-image: url("../components/images/home_page_background.png"); */
  background: 
    linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 70%),
    url("../components/images/home_page_background.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: rgb(0, 0, 0);
  letter-spacing: 0.07em;
}

.home .home_module:nth-child(1) > div {
  /* background-color: rgba(255, 255, 255, 0.1); */
  padding-top: 60px;
}

.home .home_module:nth-child(1) > div > .home_content {
  /* background-color: rgba(255, 255, 255, 0.1); */
  margin: 0 10%;
  width: 50%;
}

.home .home_module:nth-child(6) > .home_content {
  width: 80%;
}

.home_content {
  width: 60%;
  font-size: 18px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

.home_content ul {
  list-style-type: disc;
}

.home_content a {
  text-align: center;
  width: 270px;
  background-color: #0F62FE;
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.home .home_module:nth-child(2) {
  background-color: white;
}

.home .home_module:nth-child(3) {
  background-color: #efe4e4;
}

.home .home_module:nth-child(4) {
  background-color: white;
}

.home .home_module:nth-child(5) {
  background-color: #ebebeb;
}

.home .home_module:nth-child(6) {
  background-color: white;
}

.home .home_module:nth-child(7) {
  background-color: #EBEBEB;
}

.home .home_module:nth-child(8) {
  background-color: white;
}

.progress_image {
  width: 100%;
  height: 240px;
  background-image: url("../components/images/progress.jpeg");
  background-repeat: no-repeat;
  background-size: auto;
}
.irow {
  display: flex;
  height: 200px;
  overflow: hidden;
}

.irow > img {
  width: 50%;
  height: 200px;
  object-fit: cover;
}

.irow > div {
  width: 50%;
  padding-top: 20px;
}

.irow:nth-child(even) {
  flex-direction: row-reverse;
}
.irow:nth-child(odd) > div {
  text-align: left;
  padding-left: 20px;
}

.irow:nth-child(even) > div {
  text-align: right;
  padding-right: 20px;
}

.sub_module {
  display: flex;
  margin: 0 auto;
}
.sub_module > div {
  width: 50%;
}


.steps li {
  font-size: 14px;
  list-style: none;
  padding: 10px;
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
}


.steps li:nth-of-type(3) {
    background: #F3F3F3;
}

.steps li:nth-of-type(5) {
    background: #F3F3F3;
}
.grid_container{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  margin: auto;
}

.grid_item{
    font-size: 14px;
    width: 300px;
    height: 250px;
}

.grid_item video{
    max-width:100%;
    height: 12rem;
}

.join_button{
    width: 16rem;
    height:3rem;
    background-color: #d02f3a;
    border: none;
    border-radius: 5px;
    margin-bottom: 40px;
}





@media screen and (max-width: 768px) {
  .home_content {
    width: 80%;
    font-size: 18px;
    margin: 0 auto;
  }
  .grid_container{
    grid-template-columns: repeat(1,1fr);
  }
}
