.about {
  .normal{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    color: #000000;
    letter-spacing: 0.07em
  
  }
  .naviFont{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
    color: #000000;
    letter-spacing: 0.07em;
    display: flex;
    flex-direction: row;
  }
  /* identical to box height */
  .headFont{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    letter-spacing: 0.07em;
  
  color: #000000;
  }
  
  .largeFont{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.07em;
  }
  .normalInter{
    
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.07em;
    text-align: left;
    color:#000000;
  }
  .largeInter{
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0.07em;
    text-align: left;
    color:#FF5964;
  }
  .whole {
    width: 100%;
    height: 7591px;
    background: rgba(255,255,255,1);
    opacity: 1;
    position: relative;
    top: 0px;
    left: 0px;
    overflow: hidden;
  }
  .about_us_text > h5{
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .about_us_text > h6{
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .about_us_text > p{
    line-height: 1.7;
  }
  .about_us_text{
    width: 80%;
    top: 30px;
    margin: 0 auto;
    overflow: hidden;
    display: block;
    opacity: 1;
  }
  .about_us_text *{
    color: black;
  }
  
  .background{
    /*position:absolute;*/
    padding: 0;
    margin: 0;
    background-image: url("../components/images/about_us_light.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    top: 0px;
    left: 0px;
    width: 100%;
    
    opacity: 1;
  }
}