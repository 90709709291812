.loading-overlay {
  position: fixed;
  top: 60px; /* Align with the height of navigation bar */
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.877);
  z-index: 1000;
}

.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner-text {
  margin-top: 10px;
  font-size: 24px;
  color: #040404;
}

.spinner {
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #acacac; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
