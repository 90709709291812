.response {
  width: 80%;
  height: 630px;
  border-radius: 5px;
  font-family: "Montserrat";
  margin: auto;
  background-color: white;
  letter-spacing: 0.05em;
  overflow-x: scroll;
  border-top: 5px solid #CDCDCD;;
}

.introduction {
  width: 85%;
  height: auto;
  margin: auto;
}

.subtitle {
  padding-top: 60px;
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  padding-left: 2px;
}

.title {
  margin-top: 5px;
  margin-bottom: 12px;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-decoration: underline;
  color: #821320;
  text-underline-offset: 4px;
}

.text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.05em;
}

text .span {
  color: red;
}
/* 
.result {
  width: 90%;
  
 
  
  background-color: white;
  
} */

.result{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: 170px;
  min-width: 24.375rem;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  font-style: normal;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  border: 1px solid #cecece;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  letter-spacing: 0.05em;
}



.number {
  font-size: 4.375rem;
  line-height: 6.25rem;
}

.middle {
  width: 30%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .middle img {
  transform: translate(-10%, 10%);
} */

.end {
  width: 85%;
  height: 100px;
  margin: auto;
  border: 2px solid #acacac;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  margin-top: 70px;
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
  line-height: 100px;
  transition: all 0.2s;
  overflow: hidden;
}
.end:hover {
  cursor: pointer;
  margin-top: 4.68rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
}

@media screen and (max-width: 768px) {
  .number{
    font-size: 20px;
  }
}