*{
  margin:0;
  padding:0;
}

body{
    max-width:1920px ;
    min-height: 100vh;

}

select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("./components/images/arrow.png");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 11px 11px;
}