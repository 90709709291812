.custom-select-wrapper {
  position: relative;
  width: 200px;
  margin: 0 auto;
  font-family: "Montserrat";
  font-size: 16px;
  color: #000000;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.07em;
}

.custom-select {
  position: relative;
  user-select: none;
  cursor: pointer;
}

.custom-select__trigger {
  position: relative;
  display: block;
  box-sizing: border-box;
  height: 44px;
  width: 323px;
  padding: 10px;
  padding-left: 15px;
  background-color: #fff;
  border: 1px solid #000000;
  border-radius: 10px;
}

.custom-select__trigger::after {
  position: absolute;
  display: block;
  content: "";
  top: 50%;
  right: 15px;
  transform: translateY(-50%) scale(1.5) rotate(0deg);
  width: 0;
  height: 0;
  border-top: 6px solid #333;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  transition: all 0.15s ease-in-out;
}

.custom-select__trigger.open::after {
    transform: translateY(-50%) scale(1.5) rotate(180deg);
  }
.custom-select.open + .custom-select__trigger:after {
  transform: translateY(-50%) rotate(180deg);
}

.custom-options {
  position: absolute;
  display: none;
  top: 120%;
  left: 0;
  right: 0;
  width: 323px;
  color: black;
  z-index: 999;
  margin: 0;
  padding: 0;
  background-color:#FFFFFF;
  border: 1px solid #000000;
border-radius: 10px;
}

.custom-options.open {
  display: block;
}

.custom-options ul {
  width:100%;
  list-style: none;
  padding: 0;
  margin: 0;
}

.custom-options li {
  height: 44px;
  padding: 10px;
  padding-left:15px;
  border-bottom: 1px solid #CDCDCD;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.custom-options li:last-of-type {
  border-bottom: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.custom-options li:hover {
  background-color: #f7f7f7;
}
