.OptionButton {
    min-width: 4.5rem;
    height: 1.5rem;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    margin-right: 1rem;
    
}
.OptionButton.active {
    background: rgb(33, 235, 79);
    color: black;
}
