.form-box {
	width: 500px;
	max-width: 100%;
	border: 1px solid #000;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.inlineContainer {
	text-align: center;
	margin-top: 40px;
	margin-bottom: 40px;
}

.cal_select_group {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	align-items: start;
}

.cal_input_control {
	flex-shrink: 0;
	width: 250px;
	border: 1px solid #000000;
	border-radius: 10px;
	overflow: hidden;
}

.checkbox_group {
	display: flex;
	justify-content: flex-start;
	margin-left: 2em;
	margin-bottom: 8px;
}

.checkbox_check {
	padding-top: 1px;
	margin-right: 10px;
}

.unit-label-container {
    width: 100%;
}

.unit-label {
    font-size:12px;
    font-weight:600;
    text-align: right;
  }
