.custom-modal .modal-body {
  font-family: Arial, sans-serif;
  font-size: 16px;
}

.custom-modal .btn-primary {
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.modal-dialog-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}
