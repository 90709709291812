
  .orgtitle {
    width: 100%;
    height: 60px;
    font-size: 32px;
    font-family: "Montserrat";
    line-height: 44px;
    margin-top: 60px;
    margin-bottom: 4px;
    text-align: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;
  }

  .orgdetails {
    display: flex;
    justify-content: center;
    gap: 50px;
    margin-bottom: 20px;
  }

  .orgcontainer {
    font-family: "Montserrat";
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
  }

  .orgcontainer .left-area {
    flex: 0 0 60%;
  }
  
  .orgcontainer .right-area {
    flex: 0 0 40%;
  }

  .org-area {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .org-item {
    width: 75%;
    padding: 10px;
  }

  .member-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .member-table th, .member-table td {
    padding: 8px;
    border: 1px solid #ddd;
  }
  
  .member-table th {
    background-color: #f2f2f2;
  }
  
  .member-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .remove-button, .transfer-button, .invite-button, .accept-invite-btn, .cancel-button, .resend-button, .remove-email-button, .add-email-button {
    padding: 5px 10px;
    margin: 2px;
    border: none;
    background-color: #dc3545;
    opacity: 0.9;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .transfer-button, .resend-button {
    background-color: #007bff;
  }

  .invite-button, .accept-invite-btn, .remove-email-button, .add-email-button {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: black;
  }

  [data-tag-item] {
    padding: 5px;
  }

  .accept-invite-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .button-container button {
    margin-left: 10px;
    padding: 4px 6px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    text-align: center;
  }
  
  .modal-content button {
    margin-top: 20px;
    padding: 8px 16px;
  }
  